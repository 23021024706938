<template>
  <div class="wrapper">
    <div class="query">
      <div class="query-actions">
        <el-button type="primary" @click="create">添加</el-button>
        <el-button type="default" @click="authorize">授权</el-button>
      </div>

      <div class="query-form">
        <el-form
          :inline="true"
          class="query-form"
          slot="search"
          :model="queryForm"
        >
          <el-form-item prop="keyword">
            <el-input
              placeholder="搜索识别码、名称..."
              v-model="queryForm.keyword"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="default" icon="el-icon-search" @click="search"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-table
      :data="list"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column label="编号" prop="id" width="50"></el-table-column>
      <el-table-column
        label="组识别码"
        prop="code"
        width="300"
      ></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="授权应用" align="center">
        <template slot-scope="scope">
          <el-tag
            v-for="(item, index) in scope.row.apps"
            :key="index"
            closable
            @close="removeAuthorize(item.id, scope.row.id)"
          >
            {{ item.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="110">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" @click="remove(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-card style="margin-top: 16px">
      <el-pagination
        :current-page="queryForm.page"
        :page-size="queryForm.size"
        :total="total"
        @current-change="onPageChange"
      >
      </el-pagination>
    </el-card>

    <authorize-form
      ref="authorizeForm"
      @on-authorized="search"
    ></authorize-form>
    <create-form ref="createForm" @on-created="onCreated"></create-form>
    <edit-form ref="editForm" @on-created="onEdit"></edit-form>
  </div>
</template>

<script>
import CreateForm from "./create";
import AuthorizeForm from "./authorize";
import EditForm from "./edit";
export default {
  components: {
    CreateForm,
    AuthorizeForm,
    EditForm,
  },
  data() {
    return {
      loading: false,
      queryForm: {
        keyword: "",
        page: 1,
        size: 10
      },
      total: 0,
      list: [],
      selectItems: [],
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      this.$api.group
        .search(this.queryForm)
        .then((res) => {
          this.list = res.content;
          this.queryForm.size = res.size;
          this.total = parseInt(res.totalElements);
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(item) {
      this.$confirm("确定要删除该项目吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.group
            .destroy(item.id)
            .then((res) => {
              console.log(res);
              this.search();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    create() {
      this.$refs.createForm.show();
    },
    handleSelectionChange(val) {
      this.selectItems = val;
    },
    authorize() {
      this.$refs.authorizeForm.show(this.selectItems);
    },
    removeAuthorize(appId, groupId) {
      this.$confirm("确定要取消该授权吗？", "取消授权", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.app.cancelAuthorize(appId, groupId).then(() => {
          this.$message({ message: "操作成功", type: "success" });
          this.search();
        });
      });
    },
    edit(item) {
      this.$refs.editForm.show(item.id);
    },
    onCreated() {
      this.search();
    },
    onEdit() {
      this.search();
    },
    onPageChange(val) {
      this.queryForm.page = val;
      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
.query {
  display: flex;
  background-color: #ffffff;
  margin: 16px 0;
  padding: 20px 16px 0 16px;

  .query-form {
    flex: 1;
    text-align: right;
  }
}
</style>