<template>
  <el-dialog :visible.sync="visible" title="授权给应用">
    <el-form :model="form" :rules="rules" label-width="80px">
      <el-form-item label="应用">
        <el-select v-model="form.appId">
          <el-option
            v-for="(item, index) in list"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="batchAuthorize">确定</el-button>
      <el-button type="default" @click="visible = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      form: {
        appId: null,
      },
      rules: {
        groupIds: { required: true, messag: "请选择应用" },
      },
      list: [],
      groupList: [],
    };
  },
  methods: {
    show(groupList) {
      this.visible = true;
      this.groupList = groupList;
      this.findAll();
    },
    findAll() {
      this.$api.app
        .all()
        .then((res) => {
          this.list = res;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    batchAuthorize() {
      this.$api.app
        .batchAuthorize(
          this.form.appId,
          this.groupList.map((item) => {
            return item.id;
          })
        )
        .then(() => {
          this.$message({ message: "授权成功", type: "success" });
          this.$emit("on-authorized");
          this.visible = false;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
  },
};
</script>