<template>
  <el-drawer :visible="visible" title="修改分组">
    <div class="drawer-form-wrapper">
      <el-form
        :rules="rules"
        :model="form"
        style="margin: 0 16px"
        label-position="left"
      >
        <el-form-item label="组识别码" prop="code">
          <el-input placeholder="请输入..." v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="分组名称" prop="name">
          <el-input placeholder="请输入..." v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"  :loading="submiting" @click="save">
            确定
          </el-button>
          <el-button type="default" @click="visible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      submiting: false,
      form: {
        code : "",
        name: "",
        remark: "",
      },
      rules: {
        name: { required: true, message: "请输入分组名称..." },
        dode: { required: true, message: "请输入组识别码..." },
      },
    };
  },
  methods: {
    show(id) {
      this.visible = true;
      this.loading = true;
      this.$api.group
      .show(id)
      .then((res) => {
        this.form = res;
      })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.submiting = true;
      this.$api.group
        .update(this.form.id, this.form)
        .then((res) => {
          this.$message({ message: "提交成功", type: "success" });
          this.visible = false;
          this.$emit('on-created', res)
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        })
        .finally(() => {
          this.submiting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}

.drawer-form-wrapper {
  margin: 0 20px;
}
</style>