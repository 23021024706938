<template>
  <el-drawer :visible.sync="visible" title="添加分组">
    <div class="drawer-form-wrapper">
      <el-form :rules="rules" :model="form">
        <el-form-item label="组识别码" prop="code">
          <el-input placeholder="请输入..." v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="分组名称" prop="name">
          <el-input placeholder="请输入..." v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="分组备注" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入..."
            v-model="form.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="save">
            确定
          </el-button>
          <el-button type="default" @click="visible = false">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      form: { name: "", code: "", remark: "" },
      rules: {
        code: { required: true, message: "请输入编码..." },
        name: { required: true, message: "请输入分组名称..." },
      },
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    save() {
      this.loading = true;
      this.$api.group
        .create(this.form)
        .then((res) => {
          this.form.name = '';
          this.form.code = '';
          this.form.remark = '';
          this.visible = false;
          this.$emit('on-created', res)
        })
        .catch((error) => {
          this.$mesage({ message: error, type: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}

.drawer-form-wrapper {
  margin: 0 20px;
}
</style>